/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import constants from 'config/constants';
import {Redirect} from 'react-router';

const {IS_DUOC_DESIGN} = constants;

const HomeNew = lazy(() => import('views/c1do1LandingPage/Home'));
const AboutNew = lazy(() => import('views/c1do1LandingPage/About'));
const PressNew = lazy(() => import('views/c1do1LandingPage/Press'));
const InvestigationNew = lazy(() => import('views/c1do1LandingPage/Investigation'));
//const MethodologyNew = lazy(() => import('views/c1do1LandingPage/Methodology'));
const LappNew = lazy(() => import('views/c1do1LandingPage/Lapp'));
const HipaaNew = lazy(() => import('views/c1do1LandingPage/Hipaa'));
const Platform = lazy(() => import('views/Home/platform'));
const LTILanding = lazy(() => import('views/LTILanding'));
const LTISelection = lazy(() => import('views/LTISelection'));
const Courses = lazy(() => import('views/Home/courseRoutes'));
const Finance = lazy(() => import('views/c1do1LandingPage/Finance'));
const Contact = lazy(() => import('views/c1do1LandingPage/Contact'));

const DUOC_ROUTES =  [
  { path: '/lti', component: props => <LTILanding {...props} />, exact: true },
  { path: '/lti/courses/selection', component: props => <LTISelection {...props} />, exact: true },
  { path: '/courses', component: props => <Courses {...props}/>},
  { path: '/platform', component: props => <Redirect to="/"  />, exact: true },
  { path: '/', component: props => <Platform {...props} /> },


];

const C1DO1_AI_ROUTES = [
  { path: '/lti', component: props => <LTILanding {...props} />, exact: true },
  { path: '/lti/courses/selection', component: props => <LTISelection {...props} />, exact: true },
  { path: '/about', component: props => <AboutNew {...props}/>, exact: true },
  { path: '/press', component: props => <PressNew {...props}/>, exact: true },
  { path: '/investigation', component: props => <InvestigationNew {...props} />, exact: true },
  { path: '/finance', component: props => <Finance {...props} />, exact: true },
  { path: '/lapp', component: props => <LappNew {...props} />, exact: true },
 // { path: '/methodology', component: props => <MethodologyNew {...props} />, exact: true },
  { path: '/hipaa', component: props => <HipaaNew {...props} /> },
  { path: '/', component: props => <HomeNew {...props}/>,  exact: true},
  { path: '/courses', component: props => <Courses {...props}/>},
  { path: '/contact', component: props => <Contact {...props} />, exact: true },
  { path: '/', component: props => <Platform {...props} /> },
  
];

export default IS_DUOC_DESIGN ? DUOC_ROUTES : C1DO1_AI_ROUTES;